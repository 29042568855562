import { createRouter, createWebHistory } from 'vue-router';
import store from "../store/index";
import MainPage from "../components/pages/MainPage.vue";
import LoginPage from "../components/pages/LoginPage.vue";
import RegisterPage from "../components/pages/RegisterPage.vue";
import ForgotPasswordPage from "../components/pages/ForgotPasswordPage.vue";
import RecoverPasswordPage from "../components/pages/RecoverPasswordPage.vue";
import CertificatePage from "../components/pages/CertificatePage.vue";
import NotFoundPage from "../components/pages/NotFoundPage.vue";
import ProfilePage from "../components/pages/ProfilePage.vue";
import PartnerSplashPage from "../components/pages/PartnerSplashPage.vue";
import ProgramLandingPage from "../components/sections/templates/ProgramLandingPage.vue";
import ResourceLayoutPage from "../components/pages/ResourceLayoutPage.vue";
import LandingPage from "../components/pages/LandingPage.vue";

const routes = [
    { path: "/", component: MainPage, name: "home" },
    { path: "/certificate", component: CertificatePage, name: "certificate" },
    { path: "/landing", component: LandingPage, name: "landing" },
    { path: "/user/login", component: LoginPage, name: "login", meta: { pageName: "Login" } },
    { path: "/user/register", component: RegisterPage, name: "register", meta: { pageName: "Register" } },
    { path: "/user/profile", component: ProfilePage, name: "profile" },
    { path: "/user/register/:page", component: RegisterPage, meta: { pageName: "Register" } },
    { path: "/user/register/:page/:token", component: RegisterPage, meta: { pageName: "Register" } },
    {
        path: "/user/forgot-password",
        component: ForgotPasswordPage,
        name: "forgotPassword",
        meta: {
            pageName: "ForgotPassword"
        }
    },
    {
        path: "/user/reset-password/:nonce/:user",
        component: RecoverPasswordPage
    },
    { path: "/user/reset-password", component: RecoverPasswordPage, meta: { pageName: "RecoverPassword" } },
    { path: "/user/splash", component: PartnerSplashPage, name: "splash", meta: { pageName: "Splash" } },
    { path: "/resource", component: ResourceLayoutPage, name: "resources", meta: { pageName: "Resources" } },
    { path: "/user/partner-landing", component: ProgramLandingPage },
    { path: "/:pathMatch(.*)*", component: NotFoundPage, name: "not-found" }
];

const router = createRouter({
    history: createWebHistory(process.env.MIX_BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return { selector: to.hash };
        }

        return { top: 0, left: 0 };
    }
});

router.beforeEach(async (to, from, next) => {
    let guestPaths = [
        "/user/login",
        "/landing",
        "/user/forgot-password",
        "/user/register",
        "/user/profile"
    ];
    let adminPaths = ["/", "/user/profile", "partner-landing", "/certificate"];

    if (gtag && to.path !== "/") {
        gtag("config", dataLayer[1][1], { page_path: to.path });
    }

    let locationPathnameBeginning = to.path
        .split("/")
        .slice(0, 3)
        .join("/");

    let userAuthenticated = false;

    if (!store.getters.userLoadStatus) {
        try {
            let userResponse = await axios.get("/userdata");
            store.commit("setUserData", userResponse.data.user);
            store.commit("setUserLoadStatus", 1);

            if (userResponse.data.user.id) {
                store.commit("setRecentlyAuthenticated", true);
                userAuthenticated = true;
            }
        } catch (error) {
            // Do not have to do anything since `userAuthenticated` already false.
        }
    } else {
        store.commit("setRecentlyAuthenticated", false);
        if (store.state.user.id) {
            userAuthenticated = true;
        }
    }

    if (!store.getters.translationsLoaded) {
        await store.dispatch("fetchTranslations");
    }

    if (to.meta.pageName) {
        const siteName = "MGM+ Edge";
        let contentPageTitle = store.state.pageContent ? store.state.pageContent[to.meta.pageName].content?.title?.translation : to.meta.pageName;

        if (contentPageTitle) {
            if (to.params.page) {
                contentPageTitle += ` (${to.params.page})`;
            }
        }

        document.title = `${contentPageTitle} | ${siteName}`;
    }

    if (userAuthenticated) {
        if (to.path !== "/user/reset-password" && store.state.user.pw_expired) {
            next("/user/reset-password");
        } else if (guestPaths.includes(locationPathnameBeginning)) {
            next({ name: "home" });
        } else {
            next();
        }
    } else if (!userAuthenticated) {
        if (adminPaths.includes(locationPathnameBeginning)) {
            next({ name: "landing" });
        } else {
            next();
        }
    }
});

router.afterEach((to, from) => {
    if (gtag && to.path !== "/") {
        gtag("event", "page_view", {
            page_title: to.name,
            page_location: to.path,
            page_path: to.path,
            send_to: "pageview"
        });
    }
});

export default router;

<style lang="scss" rel="stylesheet/scss">
@import "@/scss/font";
@import "@/scss/color";

.login-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .footnote {
        font-size: 12px;
        padding-top: 20px;
        width: 100%;
        margin-bottom: -100%;
    }

    .login-content {
        position: relative;
        width: 100%;
        height: 540px;
        max-width: 387px;
        margin: 0 auto;
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;

        .blur-container {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;

            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }

        .error {
            width: 100%;
            font-size: 12px;
            color: #ccaf5f;
        }
        h1.form-header {
            font-size: 43px;
            font-weight: bold;
            padding: 20px 0;
            color: #000;
            max-height: 83px;
        }

        .form-container {
            width: 100%;
            max-width: 387px;
            font-size: 16px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            z-index: 1;

            .form {
                visibility: visible;
                border-top: none;
                height: 100%;
                max-width: 247px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                align-content: center;
                padding: 20px 0;
                font-weight: 300;
                margin: 0 auto;

                .forgotPasswordLink {
                    font-size: 12px;
                }
            }
            &.form-body > * {
                .form * {
                    margin: 3px 0;
                }
                input {
                    width: calc(100% - 4px);
                    height: 40px;
                    font-size: 16px;
                    text-align: center;
                    border: none;

                    &::placeholder {
                        font-size: 16px;
                        color: $font-placeholder;
                    }
                }
                .button {
                    margin-top: 10px;
                }
            }

            .login-footer {
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                padding: 12px 0;
                max-height: 83px;

                p {
                    width: 100%;
                    font-size: 12px;
                }
            }
        }

        .login-footer {
            //padding: 13px 0 0 0;
            //margin: 0px;
            font-size: 13px;
            text-align: center;

            .register-link {
                color: $primary-color;
            }
        }

        .submit-button {
            background-color: $primary-color;
            border: none;
            color: $carousel-button;

            .arrow {
                background-image: url("../../assets/images/right-arrow-dark.png");
                width: 15px;
                height: 26px;
                background-size: cover;
            }
        }

        p {
            color: $text-primary;
            text-align: center;
        }
        .copy {
            margin-bottom: 15px;
        }

        @media (max-width: 767px) {
        }
    }
}
</style>

<template>
    <div id="main-page">
        <nav-header
            :displayMenu="1"
            :toggleMenu="menu"
            :pageContent="pageContent"
        ></nav-header>
        <div id="main-container" class="container-fluid">
            <pagepiling>
                <section-wrapper class="login-template">
                    <div class="content-container">
                        <div class="login-wrapper">
                            <div class="login-content">
                                <div class="blur-container"></div>
                                <h1
                                    class="form-container transparent form-header"
                                >
                                    {{ t("title") }}
                                </h1>
                                <div
                                    v-html="t('subheader')"
                                    class="text-align-center"
                                ></div>

                                <div
                                    v-if="1 || !submitSuccessful"
                                    class="form-container transparent form-body"
                                >
                                    <form
                                        @submit.prevent="onSubmit"
                                        autocomplete="off"
                                    >
                                        <div class="form">
                                            <input
                                                :placeholder="t('username')"
                                                name="email"
                                                id="email"
                                                type="text"
                                            />
                                            <p
                                                class="error"
                                                v-if="modelErrors.email"
                                            >
                                                {{ modelErrors.email[0] }}
                                            </p>
                                            <input
                                                :placeholder="t('password')"
                                                id="password"
                                                name="password"
                                                type="password"
                                                autocomplete="current-password"
                                            />
                                            <p
                                                class="error"
                                                v-if="modelErrors.password"
                                            >
                                                {{ modelErrors.password[0] }}
                                            </p>
                                            <button
                                                id="submit"
                                                v-html="t('submit')"
                                                class="button"
                                                :disabled="isSubmitDisabled"
                                            ></button>
                                        </div>
                                        <div class="login-footer">
                                            <router-link
                                                to="/user/forgot-password"
                                                >{{
                                                    t("forgot_password")
                                                }}</router-link
                                            >
                                            <div
                                                class="login-footer"
                                                v-html="t('footer')"
                                            ></div>
                                        </div>
                                    </form>
                                </div>
                                <div class="login-footer form-container">
                                    <br />
                                    <div
                                        class="copy"
                                        v-html="t('footer2')"
                                    ></div>
                                    <div class="logo"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section-wrapper>
            </pagepiling>
        </div>
    </div>
</template>

<script type="text/babel">
import { mapGetters } from "vuex";
import NavHeader from "../NavHeader.vue";
import IconFooter from "../IconFooter.vue";
import PageSection from "../mixins/PageSection";
import Pagepiling from "../PagePiling.vue";
import SectionWrapper from "../SectionWrapper.vue";

const defaultTranslations = {
    loginError: "Incorrect username or password"
};

export default {
    name: "login-page",
    created() {
        this.$http.get("/api/content/page").then(
            response => {
                if (response.data.success === true) {
                    PageSection.methods.setData(response);
                } else {
                    throw new Error("Could not retrieve content.");
                }
            },
            response => {
                throw new Error("Error retrieving content.");
            }
        );
    },
    data() {
        return {
            submitSuccessful: false,
            isFormOpen: true,
            modelErrors: {},
            isSubmitDisabled: false,
            menu: {
                intro: false,
                epix: false,
                profile: false,
                resource: true,
                contact: true,
                privacy: true,
                terms: false,
                logout: false
            }
        };
    },
    computed: {
        ...mapGetters(["sections", "pageContent"]),
        pageName() {
            return this.$route.meta.pageName;
        },
        submitButton() {
            if (this.pageContent != null)
                return this.pageContent[this.pageName].content.submit
                    .translation;
            return "";
        },
        content() {
            if (this.pageContent != null)
                return this.pageContent[this.pageName].content;
            return "";
        }
    },
    methods: {
        onSubmit(e) {
            this.isSubmitDisabled = true;
            const formData = new FormData(e.currentTarget);
            this.$http
                .post("/user/login", Object.fromEntries(formData.entries()))
                .then(
                    () => {
                        // Redirect to main page
                        window.location.href = "/";
                    },
                    error => {
                        switch (error.response.status) {
                            case 401:
                                this.modelErrors.email = [this.t("loginError")];
                                return;
                            case 423:
                                this.modelErrors.email = [
                                    error.response.data.message
                                ];
                                return;
                            default:
                                this.modelErrors = error.response.data.errors || {};
                        }
                    }
                )
                .then(() => {
                    this.isSubmitDisabled = false;
                });
        },
        t(name) {
            return (
                this.content[name]?.translation ??
                defaultTranslations[name] ??
                ""
            );
        }
    },
    components: {
        NavHeader,
        Pagepiling,
        SectionWrapper,
        IconFooter
    }
};
</script>

<style lang="scss" rel="stylesheet/scss">
@import "@/scss/color";

.module.copy-video {
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    max-width: 100%;
    padding: 0;

    .content-container {
        align-items: center;

        // Need extra spacing to not run into absolutely positioned nav summary
        padding-top: 80px;
    }

    .play-button {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 70px;
        height: 70px;
    }

    h1,
    p {
        max-width: 600px;
        width: 100%;
    }

    .buttons {
        margin-bottom: 20px;
    }

    .video-subtext {
        color: $module-summary-circle-locked;
        display: block;
        margin: 0 auto;
        text-align: center;
    }

    @media (max-width: 767px) {
        .play-button {
            width: 70px;
            height: 70px;
        }
    }
}
</style>

<template>
    <div class="module copy-video">
        <div class="content-container">
            <h1><content-item :config="content.header"></content-item></h1>
            <p><content-item :config="content.content"></content-item></p>
            <img
                class="play-button"
                src="@/assets/images/play-button-blue.png"
                @touchmove.stop=""
                @click="openVideoModal"
                alt="Play"
            />

            <modal v-model="isVideoOpen" @closed="closeVideoModal()">
                <content-video
                    :config="content.video"
                    v-model="videoIsPlaying"
                    :onComplete="videoCompleteCallback"
                ></content-video>
            </modal>

            <p class="video-subtext">
                <content-item
                    v-if="!showNextButton"
                    :config="content.video_subtext"
                ></content-item>
                <content-item
                    v-else
                    :config="content.video_subtext_complete"
                ></content-item>
            </p>

            <div class="buttons">
                <step-button
                    @click="previousSection()"
                    direction="left"
                    v-if="hasPrevious"
                ></step-button>
                <step-button
                    v-if="showNextButton && hasNext"
                    @click="nextSection()"
                ></step-button>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
import ModuleMixin from "../ModuleMixin";
import Modal from "../../Modal.vue";
import ContentVideo from "../../content/ContentVideo.vue";

export default {
    name: "module-copy-video",
    data() {
        let vm = this;
        let videoCallback = function() {
            vm.complete();
            vm.closeVideoModal();
        };

        return {
            videoIsPlaying: false,
            videoCompleteCallback: videoCallback,
            isVideoOpen: false,
            videoWatched: false
        };
    },
    methods: {
        openVideoModal() {
            this.isVideoOpen = true;
            this.videoIsPlaying = true;
        },
        closeVideoModal() {
            this.videoIsPlaying = false;
            this.isVideoOpen = false;
        },
        onLastModule() {
            // Do nothing. We need to watch the video to complete the module.
        }
    },
    computed: {
        showNextButton() {
            return this.isComplete && this.hasNext;
        }
    },
    mixins: [ModuleMixin],
    components: {
        Modal,
        ContentVideo
    }
};
</script>

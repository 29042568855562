<style lang="scss" rel="stylesheet/scss">
.module.activity-video {
    max-width: 100%;
    min-height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 0px;
    flex-direction: row;
    align-items: stretch;
    padding: 0;

    .content-container {
        align-items: center;

        // Need extra spacing to not run into absolutely positioned nav summary
        padding-top: 80px;
    }

    p {
        text-align: center;
        display: block;
        max-width: 580px;
    }

    .center-item {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 190px;
        height: 190px;
        position: absolute;
    }

    .image-splash {
        max-width: 508px;
        max-height: 100%;
        position: relative;
        top: 0;
        left: 0;
    }

    .image-button-container {
        padding-top: 25px;
        padding-bottom: 21px;
        max-width: 508px;
        width: 100%;
        min-height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;

        position: relative;
        text-align: center;
    }
}
</style>

<template>
    <div
        class="module activity-video"
        :style="{ 'background-image': 'url(' + getModuleBackgroundImage + ')' }"
        v-if="showActivity"
    >
        <div class="content-container">
            <h1>
                <content-item :config="content.title"></content-item>
            </h1>

            <p>
                <content-item :config="content.subheader"></content-item>
            </p>

            <div class="image-button-container">
                <!--<content-item v-if="content.splash" class="image-splash" :config="content.splash" @touchmove.stop="" @click="openVideoModal"></content-item>-->
                <img
                    class="center-item play-button"
                    src="~@/assets/images/video-play-button.png"
                    @touchmove.stop=""
                    @click="openVideoModal"
                    alt="Play"
                />
            </div>

            <modal v-model="isVideoOpen" @closed="closeVideoModal()">
                <content-video
                    :config="content.resource"
                    v-model="videoIsPlaying"
                    :onComplete="videoCompleteCallback"
                ></content-video>
            </modal>

            <button
                v-if="dateComplete == ''"
                class="button"
                @click="openVideoModal"
            >
                <content-item :config="content.button"></content-item>
            </button>
            <button
                v-else-if="dateComplete != ''"
                class="button"
                v-on:click="showQuiz"
            >
                <content-item
                    v-if="quizComplete"
                    :config="content.buttonquizcomplete"
                ></content-item>
                <content-item
                    v-else
                    :config="content.buttoncomplete"
                ></content-item>
            </button>
        </div>
    </div>
    <quiz-component
        v-else
        class="module activity-video"
        :style="{ 'background-image': 'url(' + getModuleBackgroundImage + ')' }"
        :quizId="quizId"
    ></quiz-component>
</template>

<script type="text/babel">
import ModuleMixin from "../ModuleMixin";
import QuizMixin from "../../mixins/QuizMixin";
import Modal from "../../Modal.vue";
import ContentVideo from "../../content/ContentVideo.vue";
import QuizComponent from "../../quiz/QuizComponent.vue";

export default {
    name: "activity-template-video",

    data() {
        let vm = this;
        let videoCallback = function() {
            vm.complete();
            vm.closeVideoModal();
        };

        return {
            videoIsPlaying: false,
            videoCompleteCallback: videoCallback,
            isVideoOpen: false,
            videoWatched: false,
            showActivity: true,
            quizId: this.content.quiz_id.translation,
            background: this.content.splash.source
        };
    },
    methods: {
        openVideoModal() {
            this.isVideoOpen = true;
            this.videoIsPlaying = true;
        },
        closeVideoModal() {
            this.videoIsPlaying = false;
            this.isVideoOpen = false;
        },
        onLastModule() {
            // Do nothing. We need to watch the video to complete the module.
        },
        showActivities() {
            this.endQuizNavigation();
            this.showActivity = true;
        },
        showQuiz() {
            this.startQuizNavigation(this.showActivities);
            this.showActivity = false;
        }
    },
    computed: {
        getModuleBackgroundImage() {
            return this.background;
        }
    },
    mixins: [ModuleMixin, QuizMixin],
    components: {
        QuizComponent,
        Modal,
        ContentVideo
    }
};
</script>

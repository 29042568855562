<style lang="scss" rel="stylesheet/scss">
@import "@/scss/font";

li {
    span {
        cursor: pointer;
    }
}
#right-header-section {
    margin-left: auto;
}
#nav-header {
    z-index: 100;
    justify-content: flex-start;

    .menuItemsClass {
        overflow-y: auto;
    }

    .more-nav {
        margin-right: 25px;

        .more-items {
            margin: 0 -5px;
            display: flex;
        }

        .more-item {
            padding: 0 5px;

            a {
                @extend .more-nav-font;
                text-decoration: none;
                font-size: 15px;
                margin: 0 10px;
            }
        }
    }

    @media (max-width: 767px) {
        .more-nav {
            display: none;
        }
    }
}
</style>

<template>
    <nav id="nav-header">
        <router-link
            :to="{ name: 'home' }"
            id="nav-logo"
            style="cursor: pointer;"
        >MGM+ Edge</router-link>
        <!-- <div id="nav-logo" style="cursor: pointer;" v-if="isSplash"></div> -->
        <partner-logo-header />
        <div class="more-nav">
            <div class="more-items">
                <!--<div class="more-item" v-for="(section, sectionIndex) in sections" >-->
                <!--<a @click.prevent="gotoSection(sectionIndex)" href="#" v-html="section.title"></a>-->
                <!--</div>-->
            </div>
        </div>
        <!-- <div class="more-nav">
            <div class="more-items">
                <div class="more-item" v-if="displayMenu">
                    <a  @click.prevent="displayModal('Profile')" href="#">Profile</a>
                    <a href="/user/logout">Logout</a>
                </div>
            </div>
        </div> -->
        <!--<div id="srk">tttttext{{toggleMenu.intro}}</div>-->
        <div id="right-header-section" v-if="displayMenu">
            <div id="language-selector" v-if="0 == 1">
                <div class="items">
                    <!--<div :class="{selected: currentLocale === 'en_us'}"><a href="/site/language?locale=en_us" v-html="this.t('Header', 'EngToggle', 0)"></a></div>-->
                    <!--<div :class="{selected: currentLocale === 'es'}"><a href="/site/language?locale=es" v-html="this.t('Header', 'SpanToggle', 0)"></a></div>-->
                </div>
            </div>
            <div id="main-menu">
                <button @click="toggle" aria-controls="menu-items" aria-label="Menu">
                    <div id="nav-icon3" :class="menuClass">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </button>
            </div>
            <focus-trap :active="!toggleMenu && isOpen">
                <div
                    id="menu-items"
                    :class="menuItemsClass"
                    v-if="!toggleMenu"
                    @click="isModalVisiable = true"
                    :aria-hidden="!isOpen"
                    tabindex="-1"
                >
                    <div>
                        <ul>
                            <template
                                v-if="!isGuest"
                                v-for="(section, sectionIndex) in sections"
                            >
                                <li v-if="section.template !== 'modules'">
                                    <a
                                        href="#"
                                        @click.prevent="gotoSection(sectionIndex)"
                                        >{{ section.title }}</a
                                    >
                                </li>
                                <template
                                    v-else-if="section.template === 'modules'"
                                >
                                    <ul>
                                        <li
                                            v-for="(module,
                                            moduleIndex) in section.modules"
                                            :class="{ disabled: module.locked }"
                                        >
                                            <a
                                                href="#"
                                                @click.prevent="
                                                    gotoModule(
                                                        sectionIndex,
                                                        moduleIndex
                                                    )
                                                "
                                                >{{ module.title }}</a
                                            >
                                        </li>
                                    </ul>
                                </template>
                            </template>
                            <template v-if="!isGuest && !isResource && !isSplash">
                                <li
                                    v-for="(section,
                                    sectionIndex) in crowdsourcingSectionsIncomplete"
                                    :key="sectionIndex"
                                >
                                    <a
                                        href="#"
                                        class="text-uppercase"
                                        @click.prevent="
                                            gotoCrowdsourcingPage(section.id)
                                        "
                                        >{{ section.title }}</a
                                    >
                                </li>
                            </template>
                            <li v-for="(reward, id) in userRewards" :key="id">
                                <a
                                    v-if="!isResource && !isSplash"
                                    href="#"
                                    @click.prevent="gotoRewardPage(id)"
                                    class="yellow"
                                    >CHOOSE A REWARD</a
                                >
                            </li>
                            <li v-if="isResource">
                                <router-link
                                    :to="{ name: 'home' }"
                                    style="cursor: pointer;"
                                    >HOME</router-link
                                >
                            </li>
                            <li v-if="!isGuest">
                                <a @click.prevent="displayModal('Profile')" href="#"
                                    >PROFILE</a
                                >
                            </li>
                            <li>
                                <router-link
                                    :to="{ name: 'resources' }"
                                    style="cursor: pointer;"
                                    >RESOURCES</router-link
                                >
                            </li>
                            <li>
                                <a
                                    href="mailto:mgmplusedge@biworldwide.com?subject=MGMplus Edge Inquiry"
                                    >CONTACT US</a
                                >
                            </li>
                            <li v-if="!isGuest">
                                <span @click.prevent="displayModal('PrivacyNotice')"
                                    >PRIVACY NOTICE</span
                                >
                            </li>
                            <li v-if="!isGuest">
                                <span @click.prevent="displayModal('CookieNotice')"
                                    >COOKIE NOTICE</span
                                >
                            </li>
                            <li v-if="!isGuest">
                                <span
                                    @click.prevent="displayModal('TermsConditions')"
                                    >TERMS & CONDITIONS</span
                                >
                            </li>
                            <li v-if="!isGuest">
                                <a href="/user/logout">LOG OUT</a>
                            </li>
                            <li v-if="bookText !== ''">
                                <a
                                    :href="bookLink"
                                    target="_blank"
                                    v-html="bookText"
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </focus-trap>
            <focus-trap :active="toggleMenu && isOpen">
                <div
                    id="menu-items"
                    :class="menuItemsClass"
                    v-if="toggleMenu"
                    @click="isModalVisiable = true"
                    :aria-hidden="!isOpen"
                    tabindex="-1"
                >
                    <div>
                        <ul>
                            <template v-for="(section, sectionIndex) in sections">
                                <li
                                    v-if="
                                        section.template !== 'modules' &&
                                            shouldEnable(sectionIndex)
                                    "
                                >
                                    <a
                                        href="#"
                                        @click.prevent="gotoSection(sectionIndex)"
                                        >{{ section.title }}</a
                                    >
                                </li>
                                <template
                                    v-else-if="section.template === 'modules'"
                                >
                                    <ul v-if="shouldEnable(sectionIndex)">
                                        <li
                                            v-for="(module,
                                            moduleIndex) in section.modules"
                                            :class="{ disabled: module.locked }"
                                        >
                                            <a
                                                href="#"
                                                @click.prevent="
                                                    gotoModule(
                                                        sectionIndex,
                                                        moduleIndex
                                                    )
                                                "
                                                >{{ module.title }}</a
                                            >
                                        </li>
                                    </ul>
                                </template>
                            </template>
                            <template v-if="!isGuest && !isResource && !isSplash">
                                <li
                                    v-for="(section,
                                    sectionIndex) in crowdsourcingSectionsIncomplete"
                                    :key="sectionIndex"
                                >
                                    <a
                                        href="#"
                                        class="text-uppercase"
                                        @click.prevent="
                                            gotoCrowdsourcingPage(section.id)
                                        "
                                        >{{ section.title }}</a
                                    >
                                </li>
                            </template>
                            <li v-for="(reward, id) in userRewards" :key="id">
                                <a
                                    href="#"
                                    @click.prevent="gotoRewardPage(id)"
                                    class="yellow"
                                    >CHOOSE A REWARD</a
                                >
                            </li>
                            <li v-if="isResource">
                                <router-link
                                    :to="{ name: 'home' }"
                                    style="cursor: pointer;"
                                    >HOME</router-link
                                >
                            </li>
                            <li v-if="toggleMenu.profile">
                                <a @click.prevent="displayModal('Profile')" href="#"
                                    >PROFILE</a
                                >
                            </li>
                            <li v-if="toggleMenu.resource">
                                <router-link
                                    :to="{ name: 'resources' }"
                                    style="cursor: pointer;"
                                    >RESOURCES</router-link
                                >
                            </li>
                            <li v-if="toggleMenu.contact">
                                <a
                                    href="mailto:mgmplusedge@biworldwide.com?subject=MGMplus Edge Inquiry"
                                    >CONTACT US</a
                                >
                            </li>
                            <li v-if="!isGuest">
                                <span @click.prevent="displayModal('PrivacyNotice')"
                                    >PRIVACY NOTICE</span
                                >
                            </li>
                            <li v-if="!isGuest">
                                <span @click.prevent="displayModal('CookieNotice')"
                                    >COOKIE NOTICE</span
                                >
                            </li>
                            <li v-if="toggleMenu.terms">
                                <span
                                    @click.prevent="displayModal('TermsConditions')"
                                    >TERMS & CONDITIONS</span
                                >
                            </li>
                            <li v-if="!isGuest">
                                <a href="/user/logout">LOG OUT</a>
                            </li>
                            <li v-if="bookText !== ''">
                                <a
                                    :href="bookLink"
                                    target="_blank"
                                    v-html="bookText"
                                ></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </focus-trap>
        </div>
        <modal
            v-model="isModalOpen.Profile"
            v-if="isModalOpen.Profile"
            :value="true"
            :theme="'vex-middle-close vex-full-screen'"
        >
            <profile-page @saved="closeProfile"></profile-page>
        </modal>
        <modal
            v-model="isModalOpen.Resources"
            v-if="isModalOpen.Resources"
            :value="true"
            :theme="'vex-middle-close'"
        >
            <resource-page></resource-page>
        </modal>
        <modal
            v-model="isModalOpen.PrivacyPolicy"
            v-if="isModalOpen.PrivacyPolicy"
            :value="true"
            :theme="'vex-middle-close vex-full-screen'"
        >
            <policy-modal-page pageName="PrivacyPolicy" />
        </modal>
        <modal
            v-model="isModalOpen.PrivacyNotice"
            v-if="isModalOpen.PrivacyNotice"
            :value="true"
            :theme="'vex-middle-close vex-full-screen'"
        >
            <policy-modal-page pageName="PrivacyNotice" />
        </modal>
        <modal
            v-model="isModalOpen.CookieNotice"
            v-if="isModalOpen.CookieNotice"
            :value="true"
            :theme="'vex-middle-close vex-full-screen'"
        >
            <policy-modal-page pageName="CookieNotice" />
        </modal>
        <modal
            v-model="isModalOpen.TermsConditions"
            v-if="isModalOpen.TermsConditions"
            :value="true"
            :theme="'vex-middle-close vex-full-screen'"
        >
            <policy-modal-page pageName="TermsConditions" />
        </modal>
    </nav>
</template>

<script type="text/babel">
import { mapGetters } from "vuex";
import NavIcon from "./NavIcon.vue";
import TranslationMixin from "./mixins/TranslationMixin";
import PageSection from "./mixins/PageSection";
import Modal from "./Modal.vue";
import ProfilePage from "./pages/ProfilePage.vue";
import ResourcePage from "./pages/ResourcePage.vue";
import ContactUsPage from "./pages/ContactUsPage.vue";
import PolicyModalPage from "./pages/PolicyModalPage.vue";
import PartnerLogoHeader from "./PartnerLogoHeader.vue";
import { FocusTrap } from 'focus-trap-vue';

// KJL NOTE: language-selector and menu-items have v-if="0==1" to disable them currently

export default {
    props: ["pageContent", "displayMenu", "toggleMenu"],
    name: "nav-header",
    mounted() {
        this.$el.addEventListener("openCookieNotice", () => {
            this.displayModal("CookieNotice");
        });
    },
    data() {
        return {
            isOpen: false,
            isModalOpen: {
                Profile: false,
                Resources: false,
                ContactUs: false,
                PrivacyPolicy: false,
                PrivacyNotice: false,
                CookieNotice: false,
                TermsConditions: false
            },
            isModalVisiable: false,
            modalComponenet: "Profile"
        };
    },
    methods: {
        toggle() {
            this.isOpen = !this.isOpen;
        },
        gotoModule(sectionIndex, moduleIndex) {
            if (this.sections[sectionIndex].modules[moduleIndex].locked) {
                return;
            }
            // when changing sections is based off 1 not 0
            this.$store.commit("setShowRewardPage", false);
            this.$store.commit("setShowCrowdsourcingPage", false);
            this.gotoSectionModule(sectionIndex, moduleIndex);
            this.toggle();
        },
        gotoSection(index) {
            this.$store.commit("setShowRewardPage", false);
            this.$store.commit("setShowCrowdsourcingPage", false);
            this.gotoSectionModule(index);
            this.isOpen = false;
        },
        gotoRewardPage(id) {
            this.$store.commit("setShowCrowdsourcingPage", false);
            this.$store.commit("setCurrentRewardId", id);
            this.$store.commit("setShowRewardPage", true);
            this.toggle();
        },
        gotoCrowdsourcingPage(id) {
            this.$store.commit("setShowRewardPage", false);
            this.$store.commit("setCurrentCrowdsourcingId", id);
            this.$store.commit("setShowCrowdsourcingPage", true);
            this.toggle();
        },
        displayModal(page) {
            this.isModalOpen[page] = true;
            this.toggle();
        },
        closeProfile(userData) {
            this.$store.commit("updateUser", userData);
            this.closeModal("Profile");
        },
        closeModal(page) {
            this.isModalOpen[page] = false;
        },
        shouldEnable(index) {
            if (index === 0 && this.toggleMenu.intro) {
                return true;
            } else {
                return false;
            }
            if (index === 1 && this.toggleMenu.epix) {
                return true;
            } else {
                return false;
            }
        }
    },
    computed: {
        ...mapGetters(["crowdsourcingSectionsIncomplete"]),
        isGuest() {
            return !this.$store.getters.user.id;
        },
        isResource() {
            return this.$route.fullPath === "/resource";
        },
        isSplash() {
            return this.$route.fullPath === "/user/splash";
        },
        menuClass() {
            return [{ open: this.isOpen }];
        },
        menuItemsClass() {
            return [{ open: this.isOpen }];
        },
        currentLocale() {
            return this.$store.state.user.language;
        },
        sections() {
            return this.$store.state.sections;
        },
        showLogoutButton() {
            return this.$store.getters.user?.showLogout;
        },
        userRewards() {
            if (!this.$store.getters.user?.rewards) {
                return {};
            }

            const unselectedRewards = {};
            Object.entries(this.$store.getters.user.rewards).forEach(entry => {
                if (entry) {
                    const [rewardId, reward] = entry;
                    if (reward.item === null) {
                        unselectedRewards[rewardId] = reward;
                    }
                }
            });

            return unselectedRewards;
        },
        bookText() {
            //return "";
            if (
                this.pageContent &&
                this.pageContent["Main"] &&
                this.pageContent["Main"]["content"] &&
                this.pageContent["Main"]["content"]["book_file_link_text"] &&
                this.pageContent["Main"]["content"]["book_file_link_text"][
                    "translation"
                ]
            ) {
                return this.pageContent["Main"]["content"][
                    "book_file_link_text"
                ]["translation"];
            }
            return "";
        },
        bookLink() {
            //return "";
            return this.pageContent["Main"]["content"]["book_file"]["fileUrl"];
        }
    },
    components: {
        PartnerLogoHeader,
        PolicyModalPage,
        ContactUsPage,
        ResourcePage,
        ProfilePage,
        Modal,
        NavIcon,
        FocusTrap
    },
    mixins: [TranslationMixin, PageSection]
};
</script>

<style lang="scss" rel="stylesheet/scss">
@import "@/scss/font";
@import "@/scss/color";

.reward-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    bottom: 0;
    flex: 1;
    position: relative;
    overflow-y: auto;
    align-items: center;
    background-color: $background-color;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 0;
    min-width: 100vw;

    .content-container {
        flex: 1;
    }

    .reward-content {
        width: 100%;
        max-width: 750px;
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 10px;
        box-sizing: border-box;

        h1.form-header {
            font-size: 24px;
            line-height: 50px;
            margin-top: 30px;
            font-weight: bold;
        }

        .form-container {
            width: 100%;
            min-width: 387px;
            text-align: center;
            justify-content: center;
            align-content: center;
            font-size: 16px;

            .form {
                visibility: visible;
                border-top: none;
                height: 100%;
            }
            .error {
                width: 100%;
                color: #ccaf5f;
                font-weight: bold;
                margin-bottom: 1rem;
            }
            .success-message {
                margin-bottom: 1rem;
            }

            &.form-body > * {
                .form {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: flex-end;
                }
                .form-group {
                    width: 100%;
                }
                .form-col {
                    width: calc(50% - 10px);
                }
                .product-description {
                    height: 70px;
                }
                .form * {
                    margin: 5px 0;
                }
                img {
                    max-width: 300px;
                    max-height: 300px;
                }
                input,
                select {
                    width: calc(100% - 4px);
                    height: 25px;
                    font-size: 16px;
                    text-align: center;
                    border: none;
                    border-bottom: #ccaf5f 1px solid;

                    &::placeholder {
                        font-size: 16px;
                        color: $font-placeholder;
                    }
                }
                select {
                    width: 100%;
                    height: 44px;
                }
                select.placeholder-font {
                    color: $font-placeholder;
                }
                input.half,
                select.half {
                    width: calc(50% - 10px);
                }
                button.half,
                input[type="submit"].half {
                    width: calc(50% - 6px);
                }
                .register-footer {
                    margin: -5px 0 0 0;
                    color: $text-footer;
                }

                .full-width {
                    width: 100%;
                }
                .left {
                    float: left;
                }
                .right {
                    float: right;
                }
            }
        }

        .reward-footer {
            padding: 13px 0 0 0;
            margin: 0px;
            font-size: 13px;
        }

        input[type="radio"] {
            position: absolute;
            left: -10000px;
            top: -10000px;
            width: 1px;
            height: 1px;
            visibility: hidden;
        }

        input[type="radio"] + label {
            display: block;
        }

        input[type="radio"] + label::after {
            content: " ";
            display: block;
            width: 24px;
            height: 24px;
            border-radius: 20px;
            background-color: #dedede;
            margin: auto;
        }

        input[type="radio"]:checked + label::after {
            background-color: #ccaf5f;
        }

        .submit-button {
            background-color: $primary-color;
            border: none;
            color: $carousel-button;

            .arrow {
                background-image: url("../../assets/images/right-arrow-dark.png");
                width: 15px;
                height: 26px;
                background-size: cover;
            }
        }

        p {
            color: $text-primary;
        }

        @media (max-width: 767px) {
            .reward-content .form-container.form-body > * .form-col {
                width: 100%;
            }
            .reward-content .form-container.form-body > * .form {
                min-width: 100%;
                flex-wrap: wrap;
            }
        }
    }
}
@media (max-width: 767px) {
    .reward-page {
        top: 60px;
        height: calc(100vh - 60px);
        min-height: calc(100vh - 60px);
    }
}
</style>

<template>
    <div
        class="reward-page"
        :style="{ 'background-image': 'url(' + getBackgroundImage + ')' }"
    >
        <div class="content-container">
            <div class="reward-content">
                <div class="expander"></div>
                <h1
                    class="form-container form-header"
                    v-if="!submitSuccess"
                    v-html="t('title')"
                />

                <div class="form-container form-body active">
                    <div v-html="t('subheader')" v-if="!submitSuccess"></div>
                    <form
                        v-if="!submitSuccess"
                        action="/api/user/reward"
                        method="post"
                        autocomplete="off"
                    >
                        <div class="form">
                            <div
                                class="form-col"
                                v-for="item in items"
                                :key="item.id"
                            >
                                <div class="form-group">
                                    <input
                                        :id="'item-' + item.id"
                                        :value="item.id"
                                        name="item"
                                        type="radio"
                                        v-model="rewardModel"
                                    />
                                    <label :for="'item-' + item.id">
                                        <img :src="item.product_image" :alt="item.product_name" />
                                        <h2>{{ item.product_name }}</h2>
                                        <p class="product-description">
                                            {{ item.product_description }}
                                        </p>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <p class="error" v-if="modelErrors.item">
                            {{ modelErrors.item[0] }}
                        </p>
                        <button
                            class="button inline"
                            v-html="t('submit')"
                            v-on:click="nextButton"
                            :disabled="submitting"
                        ></button>
                    </form>
                    <div v-else>
                        <div
                            class="success-message"
                            v-html="successData.translation"
                        ></div>
                        <button class="button inline" v-on:click="close">
                            Close
                        </button>
                    </div>
                </div>

                <div
                    class="reward-footer"
                    v-html="t('footer')"
                    v-if="!submitSuccess"
                ></div>
                <div class="expander"></div>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
import ReplaceMixin from "../mixins/ReplaceMixin";
import { mapGetters, mapMutations } from "vuex";

export default {
    name: "reward-page",
    computed: {
        ...mapGetters(["user"]),
        getBackgroundImage() {
            return this.$store.getters.getBackground;
        },
        pageContent() {
            return this.$store.state.pageContent;
        },
        currentId() {
            return this.$store.state.currentRewardId;
        },
        items() {
            if (this.$store.state.user.rewards[this.currentId] != null)
                return this.$store.state.user.rewards[this.currentId].items;
            return [];
        },
        submitButton() {
            if (this.$store.state.pageContent != null)
                return this.$store.state.pageContent.Reward.content.submit
                    .translation;
            return "";
        },
        successData() {
            return this.replaceData(
                Object.assign({}, this.content.success),
                this.user
            );
        },
        content() {
            if (this.$store.state.pageContent != null)
                return this.$store.state.pageContent.Reward.content;
            return "";
        }
    },
    data() {
        return {
            pageName: "Reward",
            submitting: false,
            submitSuccess: false,
            modelErrors: {},
            rewardModel: {}
        };
    },
    methods: {
        ...mapMutations(["setShowRewardPage"]),
        t(name) {
            if (
                this.content != "" &&
                typeof this.content[name] != "undefined"
            ) {
                return this.content[name].translation;
            }
            return "";
        },
        nextButton(e) {
            e.stopPropagation();
            e.preventDefault();
            this.modelErrors = {};
            this.submitSuccess = false;
            this.submitting = true;

            // validate the form in its entirety
            let formData = {};
            formData.item = this.rewardModel;
            formData.id = this.currentId;
            this.$http
                .post("/api/user/reward", formData)
                .then(
                    response => {
                        const { hasErrors, errors } = response.data;

                        if (hasErrors) {
                            this.modelErrors = errors;
                            return;
                        }

                        // close page
                        this.$store.commit("setUserItem", formData.item);
                        this.submitSuccess = true;
                    },
                    error => {
                        const { hasErrors, errors } = error.response.data;

                        if (hasErrors) {
                            this.modelErrors = errors;
                            return;
                        }

                        throw new Error("Error validating content.");
                    }
                )
                .then(() => {
                    this.submitting = false;
                });
        },
        close() {
            this.setShowRewardPage(false);
        }
    },
    mixins: [ReplaceMixin],
    components: {}
};
</script>

<style lang="scss" rel="stylesheet/scss">
@import "@/scss/color";

@media all {
    body {
        -webkit-print-color-adjust: exact;
    }

    .certificate-page {
        height: 100vh;
        display: flex;
        flex-direction: column;
        overflow: auto;

        h1 {
            font-size: 146px;
            margin-bottom: 20px;
            margin-top: 20px;
        }

        .subtext {
            font-size: 36px;
            margin-bottom: 170px;
        }

        p {
            text-align: center;
            width: 100%;
        }

        .data-line {
            font-size: 32px;
            border-bottom: 1px solid black;
            width: 100%;
            text-align: center;
            line-height: 1;
        }

        .data-line.name {
            max-width: 792px;
            margin-bottom: 5px;
        }

        .data-line.completion-date {
            max-width: 467px;
        }

        .course-info {
            font-size: 20px;
        }

        h1,
        strong {
            color: $primary-color;
            width: 100%;
        }

        .blue-bar {
            height: 23px;

            img {
                height: 23px;
                width: 100%;
            }
        }

        .blue-bar,
        .footer {
            flex-shrink: 0;
        }

        .signature-wrapper {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
        }

        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            flex: 1 0 auto;
            padding: 40px 20px 40px;
        }

        .our-promise-icon {
            width: 98px;
            height: 98px;
        }

        .footer {
            height: 60px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            img {
                height: 60px;
            }

            img:first-child {
                width: 100%;
            }
        }

        h1 {
            font-size: 70px;
        }

        .subtext {
            font-size: 30px;
        }

        .data-line {
            width: 80%;
        }

        h1 {
            margin-bottom: 40px;
        }

        .our-promise-icon {
        }

        .subtext {
            margin-bottom: 40px;
        }

        .data-line {
            font-size: 30px;
        }

        .data-line.name {
            margin-bottom: 0;
        }

        .course-info {
            font-size: 20px;
            margin-bottom: 40px;
            margin-top: 5px;
        }

        .footer {
            .att-logo {
                width: 30px;
                height: 30px;
                margin-right: 40px;
            }
        }
    }
}
</style>

<template>
    <div class="certificate-page">
        <div class="blue-bar">
            <img src="@/assets/images/blue-background.jpg" alt="" />
        </div>
        <div class="content">
            <div class="our-icon"></div>

            <h1 v-html="t('Certificate', 'Header', 0)"></h1>
            <p class="subtext" v-html="t('Certificate', 'Content', 0)"></p>

            <div class="signature-wrapper">
                <div class="data-line name">{{ fullName }}</div>
                <div
                    class="course-info"
                    v-html="t('Certificate', 'CourseInfo', 0)"
                ></div>
            </div>

            <div class="data-line completion-date">{{ completionDate }}</div>
        </div>
        <div class="footer">
            <!-- <img src="@/assets/images/black-background.jpg" /> -->
            <!-- <img src="@/assets/images/footer-logo.jpg" /> -->
        </div>
    </div>
</template>

<script type="text/babel">
import translator from "../../api/translator";
import TranslationMixin from "../mixins/TranslationMixin";
export default {
    name: "certificate-page",
    created() {
        this.$http.get("/api/content").then(
            response => {
                if (response.data.success === true) {
                    translator.install(response.data.pages);
                } else {
                    throw new Error("Could not retrieve content.");
                }
            },
            response => {
                throw new Error("Error retrieving content.");
            }
        );
    },
    data() {
        return {};
    },
    computed: {
        fullName() {
            return this.$store.state.user.name;
        },
        completionDate() {
            return this.$store.state.user.modules_completion_date;
        }
    },
    mixins: [TranslationMixin]
};
</script>

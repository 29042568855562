<style lang="scss" rel="stylesheet/scss">
@import "@/scss/font";
@import "@/scss/color";

.forgot-password-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    color: $text-primary;

    .footnote {
        font-size: 12px;
        padding-top: 20px;
        width: 100%;
        margin-bottom: -100%;
    }

    .forgot-password-content {
        position: relative;
        width: 100%;
        max-width: 387px;
        margin: 0 auto;
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;

        .blur-container {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            // filter: blur(10px);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }

        h1.form-header {
            font-size: 43px;
            font-weight: bold;
            color: #000;
            padding: 20px 0;
            max-height: 83px;
        }

        .form-container {
            width: 100%;
            max-width: 387px;
            font-size: 16px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            .form {
                visibility: visible;
                border-top: none;
                height: 100%;
                max-width: 247px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                align-content: center;
                padding: 0 0 20px 0;
                font-weight: 300;

                .forgotPasswordLink {
                    font-size: 12px;
                }
            }
            &.form-body > * {
                padding: 18px 69px 40px 69px;

                .form * {
                    margin: 5px 0;
                }
                input {
                    width: calc(100% - 4px);
                    height: 40px;
                    font-size: 16px;
                    text-align: center;
                    border: none;

                    &::placeholder {
                        font-size: 16px;
                        color: $font-placeholder;
                    }
                }
            }
            .forgot-password-footer {
                width: 100%;

                padding: 6px 0 0 0;
                font-size: 14px;
                text-align: center;
                margin: 0px 0 0 0;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: center;
                color: $text-footer;
                max-height: 83px;
            }
        }
        .forgot-password-footer {
            padding: 13px 0 0 0;
            margin: 0px;
        }

        .submit-button {
            background-color: $primary-color;
            border: none;
            color: $carousel-button;

            .arrow {
                background-image: url("../../assets/images/right-arrow-dark.png");
                width: 15px;
                height: 26px;
                background-size: cover;
            }
        }

        p {
            text-align: center;
        }
        .success {
            padding: 22px;
            width: 89%;
        }

        @media (max-width: 767px) {
        }
    }
}
</style>

<template>
    <div id="main-page">
        <nav-header
            :displayMenu="1"
            :toggleMenu="menu"
            :pageContent="pageContent"
        ></nav-header>
        <div id="main-container" class="container-fluid">
            <pagepiling>
                <section-wrapper class="forgot-password-template">
                    <div class="content-container">
                        <div class="forgot-password-wrapper">
                            <div class="forgot-password-content">
                                <div class="blur-container"></div>
                                <h1
                                    class="form-container transparent form-header"
                                >
                                    {{ t("title") }}
                                </h1>

                                <div
                                    v-if="!submitSuccess"
                                    class="form-container transparent form-body active"
                                >
                                    <form
                                        action="/user/forgot-password"
                                        method="post"
                                        autocomplete="off"
                                        @submit.prevent="onSubmit"
                                    >
                                        <div class="form">
                                            <div v-html="t('subheader')"></div>
                                            <input
                                                :placeholder="t('email')"
                                                name="email"
                                                type="text"
                                            />
                                            <p
                                                class="error"
                                                v-if="modelErrors.email"
                                            >
                                                {{ modelErrors.email[0] }}
                                            </p>
                                            <button
                                                v-html="t('submit')"
                                                class="button"
                                                :disabled="submitting"
                                            ></button>
                                        </div>
                                        <div
                                            class="forgot-password-footer"
                                            v-html="t('footer')"
                                        ></div>
                                    </form>

                                    <div
                                        class="forgot-password-footer form-container"
                                    >
                                        <div
                                            class="copy"
                                            v-html="t('footer2')"
                                        ></div>
                                        <div class="logo"></div>
                                    </div>
                                </div>

                                <div
                                    v-else
                                    v-html="t('success')"
                                    class="success"
                                ></div>
                            </div>
                            <icon-footer></icon-footer>
                        </div>
                    </div>
                </section-wrapper>
            </pagepiling>
        </div>
    </div>
</template>

<script type="text/babel">
import { mapGetters } from "vuex";
import NavHeader from "../NavHeader.vue";
import IconFooter from "../IconFooter.vue";
import PageSection from "../mixins/PageSection";
import Pagepiling from "../PagePiling.vue";
import SectionWrapper from "../SectionWrapper.vue";

// Import all section template components
// Foreach incoming section, find the correct template component, and put it in a page piling with the corresponding data.

export default {
    name: "forgot-password-page",
    created() {
        this.$http.get("/api/content/page").then(
            response => {
                if (response.data.success === true) {
                    PageSection.methods.setData(response);
                } else {
                    throw new Error("Could not retrieve content.");
                }
            },
            response => {
                throw new Error("Error retrieving content.");
            }
        );
    },
    computed: {
        ...mapGetters(["sections", "pageContent"]),
        pageName() {
            return this.$route.meta.pageName;
        },
        submitButton() {
            if (this.pageContent != null)
                return this.pageContent[this.pageName].content
                    .submit.translation;
            return "";
        },
        content() {
            if (this.pageContent != null)
                return this.pageContent[this.pageName].content;
            return "";
        }
    },
    watch: {},
    data() {
        return {
            menu: {
                intro: false,
                epix: false,
                profile: false,
                resource: false,
                contact: true,
                privacy: true,
                terms: false,
                logout: false
            },
            modelErrors: {},
            submitSuccess: false,
            submitting: false
        };
    },
    methods: {
        onSubmit(e) {
            this.submitting = true;
            const formData = new FormData(e.currentTarget);
            this.$http
                .post(
                    "/user/forgot-password",
                    Object.fromEntries(formData.entries())
                )
                .then(
                    response => {
                        const { hasErrors, errors } = response.data;

                        if (hasErrors) {
                            this.modelErrors = errors;
                            return;
                        }

                        this.submitSuccess = true;
                    },
                    error => {
                        this.modelErrors = error.response.data.errors || {};
                    }
                )
                .then(() => {
                    this.submitting = false;
                });
        },
        t(name) {
            if (
                this.content != "" &&
                typeof this.content[name] != "undefined"
            ) {
                return this.content[name].translation;
            }
            return "";
        }
    },
    components: {
        NavHeader,
        Pagepiling,
        SectionWrapper,
        IconFooter
    }
};
</script>

<style lang="scss" rel="stylesheet/scss">
@import "@/scss/font";
@import "@/scss/color";
@import "@/scss/breakpoints";
@import "@/scss/helper/_mixin";

.register-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .register-content {
        position: relative;
        width: 100%;
        height: auto;
        max-width: 387px;
        margin: 0 auto;
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        margin: auto;

        select::-ms-expand {
            display: none;
        }

        .blur-container {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            // filter: blur(10px);
            //background: url('/images/backgrounds/epix-background-image.jpg');
            background-repeat: no-repeat;
            background-size: 100% 92%;
            background-position: 0 86px;
            background-attachment: fixed;
        }

        h1.form-header {
            font-size: 43px;
            font-weight: bold;
            color: #000;
            padding: 20px 0;
            max-height: 83px;
        }

        .form-container {
            width: 100%;
            max-width: 387px;
            text-align: center;
            justify-content: center;
            align-content: center;
            font-size: 16px;

            .form {
                visibility: visible;
                border-top: none;
                height: 100%;
            }
            .error {
                width: 100%;
                font-size: 12px;
                color: #ccaf5f;
            }
            &.form-body > * {
                padding: 0 1.5rem 0;

                @media (min-width: map-get($grid-breakpoints, "sm")) {
                    padding: 0 69px 0;
                }

                &.register-footer {
                    padding-bottom: 0;
                    padding-top: 0px;
                    line-height: 55px;
                    font-size: 16px;
                    margin-top: 15px;
                }

                .form {
                    // min-height: 396px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                }
                .form * {
                    margin: 5px 0;
                }

                input {
                    width: 100%;
                    height: 40px;
                    font-size: 16px;
                    text-align: center;
                    border: none;
                    @include input-outline;
                }

                .form-group {
                    height: 44px;
                    width: 100%;
                    box-sizing: border-box;
                    margin: 0;
                }

                select {
                    height: 44px;
                    line-height: 44px;
                    width: 100%;
                    margin: 0;
                    background: url("../../assets/images/select-arrow-black.png")
                        no-repeat top 17px right 17px;
                    background-color: #ffffff;
                    -webkit-appearance: none;
                    appearance: none;
                    -webkit-border-radius: 0px;
                    border-radius: 0px;
                    box-sizing: border-box;
                    font-size: 16px;
                    border: none;
                    @include input-outline;
                }

                .disabled {
                    color: #757575;
                    background: url("../../assets/images/select-arrow.png")
                        no-repeat top 17px right 17px;
                    background-color: #ffffff;
                    box-sizing: border-box;
                    margin: 0;
                }

                .select {
                    margin: 5px 0;
                }

                .input-container {
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }

                .input-label {
                    padding-left: 7px;
                    font-weight: normal;
                }

                .custom-checkbox {
                    cursor: pointer;
                    border: none;
                    outline: 2.5px solid rgba(0, 0, 0, 0.3);
                    background-color: #ffffff;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    height: 20px;
                    width: 20px;
                }

                .custom-checkbox:checked {
                    background: url("../../assets/images/checkmark.svg")
                        no-repeat top 2px right 2px;
                    background-color: #ffffff;
                    background-size: 80%;
                }

                input.half,
                select.half {
                    width: calc(50% - 10px);
                }
                button.half,
                input[type="submit"].half {
                    width: calc(50% - 6px);
                    min-width: auto;
                    padding-left: 0;
                    padding-right: 0;
                }
                button,
                input[type="submit"] {
                    display: inline-block;
                }
                .register-footer {
                    margin: -5px 0 0 0;
                    color: $text-footer;
                }

                .full-width {
                    width: 100%;
                }
                .left {
                    float: left;
                }
                .right {
                    float: right;
                }
            }
        }

        .register-footer {
            padding: 13px 0 0 0;
            margin: 0px;
            font-size: 14px;
        }

        .submit-button {
            background-color: $primary-color;
            border: none;
            color: $carousel-button;

            .arrow {
                background-image: url("../../assets/images/right-arrow-dark.png");
                width: 15px;
                height: 26px;
                background-size: cover;
            }
        }
        .loginfooter {
            margin-left: 6px;
        }
        .copy {
            margin-top: -15px;
            margin-botton: 10px;
            padding: 8px 15px;
        }
    }
}
.dropdowntext {
    text-align: center;
    text-align-last: center;
}
.list {
    padding-right: 35px;
}
</style>

<template>
    <div id="main-page">
        <privacy-popup
            :message="t('privacy_popup_message')"
            :privacyLinkText="t('privacy_link_text')"
            :cookieLinkText="t('cookie_link_text')"
            :buttonText="t('privacy_popup_button_text')"
        />
        <nav-header
            :displayMenu="1"
            :toggleMenu="menu"
            :pageContent="pageContent"
        ></nav-header>
        <div id="main-container" class="container-fluid">
            <pagepiling>
                <section-wrapper class="register-template">
                    <div class="content-container">
                        <div class="register-wrapper">
                            <div class="register-content">
                                <div class="blur-container"></div>
                                <h1
                                    class="form-container transparent form-header"
                                >
                                    {{ t("title") }}
                                </h1>

                                <div
                                    :class="[{ active: 1 || isFormOpen }]"
                                    class="form-container transparent form-body"
                                >
                                    <form
                                        v-if="!showSuccess"
                                        action="/user/register"
                                        method="post"
                                        autocomplete="off"
                                    >
                                        <input
                                            name="token"
                                            type="hidden"
                                            v-model="registerModel.token"
                                            v-if="registerModel.token"
                                        />
                                        <div
                                            class="form"
                                            v-if="currentPage === '1'"
                                        >
                                            <div v-html="t('subheader')"></div>

                                            <input
                                                :placeholder="t('username')"
                                                v-if="
                                                    typeof registerModel.token !==
                                                        'string'
                                                "
                                                name="username"
                                                type="text"
                                                maxlength="255"
                                                v-model="registerModel.username"
                                                :aria-label="t('username')"
                                            />
                                            <input
                                                :placeholder="t('username')"
                                                v-else
                                                disabled
                                                name="username"
                                                type="text"
                                                maxlength="255"
                                                v-model="registerModel.username"
                                                :aria-label="t('username')"
                                            />
                                            <p
                                                class="error"
                                                v-if="modelErrors.username"
                                            >
                                                {{ modelErrors.username[0] }}
                                            </p>
                                            <input
                                                :placeholder="t('first_name')"
                                                v-if="
                                                    typeof registerModel.token !==
                                                        'string'
                                                "
                                                name="firstname"
                                                type="text"
                                                maxlength="255"
                                                v-model="
                                                    registerModel.firstname
                                                "
                                                :aria-label="t('first_name')"
                                            />
                                            <input
                                                :placeholder="t('firsttname')"
                                                v-else
                                                disabled
                                                name="firstname"
                                                type="text"
                                                maxlength="255"
                                                v-model="
                                                    registerModel.firstname
                                                "
                                                :aria-label="t('firsttname')"
                                            />
                                            <p
                                                class="error"
                                                v-if="modelErrors.firstname"
                                            >
                                                {{ modelErrors.firstname[0] }}
                                            </p>
                                            <input
                                                :placeholder="t('lastname')"
                                                v-if="
                                                    typeof registerModel.token !==
                                                        'string'
                                                "
                                                name="lastname"
                                                type="text"
                                                maxlength="255"
                                                v-model="registerModel.lastname"
                                                :aria-label="t('lastname')"
                                            />
                                            <input
                                                :placeholder="t('lastname')"
                                                v-else
                                                disabled
                                                name="lastname"
                                                type="text"
                                                maxlength="255"
                                                v-model="registerModel.lastname"
                                                :aria-label="t('lastname')"
                                            />
                                            <p
                                                class="error"
                                                v-if="modelErrors.lastname"
                                            >
                                                {{ modelErrors.lastname[0] }}
                                            </p>
                                            <p style="text-align: left;">
                                                Choose a password that contains
                                                at least 14 characters. Use at
                                                least 3 of the following:
                                                uppercase, lowercase, number,
                                                symbol. Think of a password
                                                that’s easy to remember—like
                                                favorite movie, book, elementary
                                                school or work address.
                                            </p>

                                            <input
                                                :placeholder="t('password')"
                                                name="password"
                                                type="password"
                                                maxlength="255"
                                                v-model="registerModel.password"
                                                :aria-label="t('password')"
                                                autocomplete="new-password"
                                            />
                                            <p
                                                class="error"
                                                v-if="modelErrors.password"
                                            >
                                                {{ modelErrors.password[0] }}
                                            </p>
                                            <input
                                                :placeholder="
                                                    t('password_confirm')
                                                "
                                                name="password_confirm"
                                                type="password"
                                                maxlength="255"
                                                v-model="
                                                    registerModel.password_confirm
                                                "
                                                :aria-label="t('password_confirm')"
                                                autocomplete="new-password"
                                            />
                                            <p
                                                class="error"
                                                v-if="
                                                    modelErrors.password_confirm
                                                "
                                            >
                                                {{
                                                    modelErrors
                                                        .password_confirm[0]
                                                }}
                                            </p>
                                            <div
                                                class="text-align-center full-width"
                                            >
                                                <button
                                                    v-html="t('next')"
                                                    v-on:click="
                                                        nextButton(
                                                            $event,
                                                            2,
                                                            true
                                                        )
                                                    "
                                                    class="button"
                                                ></button>
                                            </div>
                                        </div>
                                        <div
                                            class="form"
                                            v-if="currentPage === '2'"
                                        >
                                            <div v-html="t('subheader')"></div>

                                            <input
                                                :placeholder="t('partner_code')"
                                                name="partnercode"
                                                type="text"
                                                maxlength="255"
                                                v-model="
                                                    registerModel.partner_code
                                                "
                                                v-on:blur="checkpartnercode"
                                                :aria-label="t('partner_code')"
                                            />
                                            <p
                                                class="error"
                                                v-if="modelErrors.partner_code"
                                            >
                                                {{
                                                    modelErrors.partner_code[0]
                                                }}
                                            </p>
                                            <select
                                                class="dropdowntext"
                                                name="locationDesignation"
                                                v-model="
                                                    registerModel.location_designation
                                                "
                                                :aria-label="t('location')"
                                            >
                                                <option
                                                    value=""
                                                    v-html="t('location')"
                                                    disabled
                                                ></option>
                                                <option
                                                    v-for="location in locationOptions"
                                                    :key="location.id"
                                                    :value="location.id"
                                                    v-html="location.name"
                                                ></option>
                                            </select>
                                            <p
                                                class="error"
                                                v-if="
                                                    modelErrors.location_designation
                                                "
                                            >
                                                {{
                                                    modelErrors
                                                        .location_designation[0]
                                                }}
                                            </p>
                                            <select
                                                class="dropdowntext"
                                                name="Title"
                                                id="title"
                                                v-model="registerModel.title"
                                                :aria-label="t('role')"
                                            >
                                                <option
                                                    value=""
                                                    v-html="t('role')"
                                                    disabled
                                                ></option>
                                                <option
                                                    v-for="(value,
                                                    key) in titleNameSelectOptions"
                                                    :value="key"
                                                    >{{ value }}</option
                                                >
                                            </select>
                                            <p
                                                class="error"
                                                v-if="modelErrors.role"
                                            >
                                                {{ modelErrors.role[0] }}
                                            </p>

                                            <div class="full-width">
                                                <button
                                                    v-html="t('previous')"
                                                    class="button half left"
                                                    v-on:click="
                                                        nextButton(
                                                            $event,
                                                            1,
                                                            false
                                                        )
                                                    "
                                                ></button>
                                                <button
                                                    v-html="t('next')"
                                                    class="button half right"
                                                    v-on:click="
                                                        nextButton(
                                                            $event,
                                                            3,
                                                            true
                                                        )
                                                    "
                                                ></button>
                                            </div>
                                        </div>
                                        <div
                                            class="form"
                                            v-if="currentPage === '3'"
                                        >
                                            <div v-html="t('subheader')"></div>
                                            <input
                                                :placeholder="t('address1')"
                                                name="address1"
                                                type="text"
                                                maxlength="255"
                                                v-model="registerModel.address1"
                                                :aria-label="t('address1')"
                                            />
                                            <p
                                                class="error"
                                                v-if="modelErrors.address1"
                                            >
                                                {{ modelErrors.address1[0] }}
                                            </p>
                                            <input
                                                :placeholder="t('address2')"
                                                name="address2"
                                                type="text"
                                                maxlength="255"
                                                v-model="registerModel.address2"
                                                :aria-label="t('address2')"
                                            />
                                            <p
                                                class="error"
                                                v-if="modelErrors.address2"
                                            >
                                                error message
                                            </p>
                                            <input
                                                :placeholder="t('city')"
                                                name="city"
                                                type="text"
                                                maxlength="255"
                                                v-model="registerModel.city"
                                                :aria-label="t('city')"
                                            />
                                            <p
                                                class="error"
                                                v-if="modelErrors.city"
                                            >
                                                {{ modelErrors.city[0] }}
                                            </p>
                                            <select
                                                class="dropdowntext half"
                                                v-bind:class="{
                                                    'placeholder-font': !registerModel.state
                                                }"
                                                name="state"
                                                v-on:focus="
                                                    registerModel.state =
                                                        registerModel.state ||
                                                        'AL'
                                                "
                                                v-model="registerModel.state"
                                                :aria-label="t('state')"
                                            >
                                                <option
                                                    value=""
                                                    v-html="t('state')"
                                                    disabled
                                                ></option>
                                                <option
                                                    v-for="(state,
                                                    stateIndex) in registerFormData.state"
                                                    :value="stateIndex"
                                                    >{{ state }}</option
                                                >
                                            </select>
                                            <p
                                                class="error"
                                                v-if="modelErrors.state"
                                            >
                                                {{ modelErrors.state[0] }}
                                            </p>
                                            <input
                                                :placeholder="t('zip')"
                                                name="zip"
                                                type="text"
                                                class="half"
                                                maxlength="5"
                                                v-model="registerModel.zip"
                                                :aria-label="t('zip')"
                                            />
                                            <p
                                                class="error"
                                                v-if="modelErrors.zip"
                                            >
                                                {{ modelErrors.zip[0] }}
                                            </p>

                                            <div class="full-width">
                                                <button
                                                    v-html="t('previous')"
                                                    class="button half left"
                                                    v-on:click="
                                                        nextButton(
                                                            $event,
                                                            2,
                                                            false
                                                        )
                                                    "
                                                ></button>
                                                <button
                                                    v-html="t('submit')"
                                                    class="button half right"
                                                    v-on:click="
                                                        nextButton(
                                                            $event,
                                                            'submit',
                                                            true
                                                        )
                                                    "
                                                ></button>
                                            </div>
                                        </div>
                                    </form>
                                    <div
                                        class="register-footer"
                                        v-html="t('footer')"
                                    >
                                    </div>
                                </div>
                                <div class="register-footer form-container">
                                    <div
                                        class="copy"
                                        v-html="t('footer2')"
                                    ></div>
                                    <div class="logo"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section-wrapper>
            </pagepiling>
        </div>
    </div>
</template>

<script type="text/babel">
import { mapGetters } from "vuex";
import NavHeader from "../NavHeader.vue";
import IconFooter from "../IconFooter.vue";
import PageSection from "../mixins/PageSection";
import Pagepiling from "../PagePiling.vue";
import SectionWrapper from "../SectionWrapper.vue";
import PrivacyPopup from "../PrivacyPopup.vue";

// Import all section template components
// Foreach incoming section, find the correct template component, and put it in a page piling with the corresponding data.

export default {
    name: "register-page",
    computed: {
        ...mapGetters(["pageContent"]),
        pageName() {
            return this.$route.meta.pageName;
        },
        submitButton() {
            if (this.pageContent != null)
                return this.pageContent[this.pageName].content.submit
                    .translation;
            return "";
        },
        content() {
            if (this.pageContent != null)
                return this.pageContent[this.pageName].content;
            return "";
        },
        currentPage() {
            return this.$route.params.page || "1";
        }
    },
    created() {
        this.$http.get("/api/content/page").then(
            response => {
                if (response.data.success === true) {
                    PageSection.methods.setData(response);
                } else {
                    throw new Error("Could not retrieve content.");
                }
            },
            response => {
                throw new Error("Error retrieving content.");
            }
        );
    },
    watch: {},
    data() {
        return {
            isFormOpen: true,
            modelHasErrors: false,
            showSuccess: false,
            modelErrors: {},
            registerModel: {
                username: null,
                firstname: null,
                lastname: null,
                password: null,
                password_confirm: null,
                partner_code: null,
                location_designation: "",
                title: "",
                address1: null,
                address2: null,
                city: null,
                state: "",
                zip: null,
                location: "",
                role: null,
                token: null
            },
            registerFormData: {
                state: {
                    AL: "AL",
                    AK: "AK",
                    AZ: "AZ",
                    AR: "AR",
                    CA: "CA",
                    CO: "CO",
                    CT: "CT",
                    DE: "DE",
                    DC: "DC",
                    FL: "FL",
                    GA: "GA",
                    HI: "HI",
                    ID: "ID",
                    IL: "IL",
                    IN: "IN",
                    IA: "IA",
                    KS: "KS",
                    KY: "KY",
                    LA: "LA",
                    ME: "ME",
                    MD: "MD",
                    MA: "MA",
                    MI: "MI",
                    MN: "MN",
                    MS: "MS",
                    MO: "MO",
                    MT: "MT",
                    NE: "NE",
                    NV: "NV",
                    NH: "NH",
                    NJ: "NJ",
                    NM: "NM",
                    NY: "NY",
                    NC: "NC",
                    ND: "ND",
                    OH: "OH",
                    OK: "OK",
                    OR: "OR",
                    PA: "PA",
                    RI: "RI",
                    SC: "SC",
                    SD: "SD",
                    TN: "TN",
                    TX: "TX",
                    UT: "UT",
                    VT: "VT",
                    VA: "VA",
                    WA: "WA",
                    WV: "WV",
                    WI: "WI",
                    WY: "WY"
                }
            },
            titleNameSelectOptions: {
                "Agent\/Rep": "Agent\/Rep",
                Coach: "Coach",
                "Manager\/Supervisor": "Manager\/Supervisor",
                Other: "Other"
            },
            locationOptions: [],
            menu: {
                intro: false,
                epix: false,
                profile: false,
                resource: true,
                contact: true,
                privacy: true,
                terms: false,
                logout: false
            }
        };
    },
    methods: {
        findLocations(formData) {
            this.$http.post("/api/user/locations", formData).then(response => {
                if (response.data.success) {
                    this.locationOptions = response.data.data;
                    if (response.data.data.length === 0) {
                        this.modelErrors.partner_code = [
                            "Invalid partner code or email address.  Check both to continue with registration."
                        ];
                    } else {
                        if (
                            typeof this.modelErrors.partner_code !== "undefined"
                        ) {
                            delete this.modelErrors.partner_code;
                        }
                    }
                }
            });
        },
        checkpartnercode() {
            this.findLocations({
                email: this.registerModel.username,
                partner_code: this.registerModel.partner_code
            });
        },
        t(name) {
            if (
                this.content !== "" &&
                typeof this.content[name] != "undefined"
            ) {
                return this.content[name].translation;
            }
            return "";
        },
        nextButton(e, page, stopPageWhenNotValidated) {
            e.stopPropagation();
            e.preventDefault();
            this.modelHasErrors = false;
            this.modelErrors = {};

            // validate the form in its entirety
            let formData = this.registerModel;
            if (formData.partner_code) {
                formData.partner_code = formData.partner_code.toUpperCase();
            };
            formData.currentPage = this.currentPage;
            this.$http.post("/api/user/register", formData).then(
                response => {
                    if (response.data.success === true) {
                        // submit the form
                        if (page === "submit") {
                            this.$http
                                .post("/api/user/register/submit/1", formData)
                                .then(
                                    response => {
                                        if (response.data.success == true) {
                                            window.location = "/user/splash";
                                        } else {
                                            throw new Error(
                                                "Error saving content."
                                            );
                                        }
                                    },
                                    () => {
                                        throw new Error(
                                            "Error saving content."
                                        );
                                    }
                                );
                            return;
                        }

                        // goto the next registration page
                        this.$router.push("/user/register/" + page);
                    } else {
                        throw new Error("Could not validate content.");
                    }
                },
                error => {
                    //check if there are errors
                    this.modelHasErrors =
                        error.response.data.hasErrors || false;

                    // found errors - must display them
                    if (
                        stopPageWhenNotValidated === true &&
                        this.modelHasErrors === true
                    ) {
                        this.modelErrors = error.response.data.errors;
                        return;
                    }

                    // goto the next registration page
                    this.$router.push("/user/register/" + page);
                }
            );
        }
    },
    components: {
        NavHeader,
        Pagepiling,
        SectionWrapper,
        IconFooter,
        PrivacyPopup
    }
};
</script>

<style lang="scss" rel="stylesheet/scss">
@import "@/scss/font";
@import "@/scss/color";

.profile-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #58595b;

    .profile-content {
        width: 100%;
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 23px 32px;
        box-sizing: border-box;

        h1.form-header {
            font-size: 30px;
            line-height: 50px;
            margin-top: 30px;
            font-weight: bold;
        }

        .form-container {
            width: 100%;
            min-width: 387px;
            text-align: center;
            justify-content: center;
            align-content: center;
            font-size: 16px;

            .form {
                visibility: visible;
                border-top: none;
                height: 100%;
            }
            .error {
                display: block;
                width: 100%;
                font-size: 12px;
                color: #ccaf5f;
            }

            &.form-body > * {
                .form {
                    min-height: 700px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }
                .form-group {
                    width: 100%;
                }
                .form-col {
                    width: calc(48% - 10px);
                }
                .form * {
                    margin: 5px 0;
                }
                input,
                select {
                    width: calc(100% - 4px);
                    height: 40px;
                    font-size: 16px;
                    //text-align: center;
                    border: none;
                    border-bottom: black 1px solid;

                    &::placeholder {
                        font-size: 16px;
                        color: $font-placeholder;
                    }
                }
                select {
                    width: 100%;
                    height: 49px;
                }
                select.placeholder-font {
                    color: $font-placeholder;
                }
                input.half,
                select.half {
                    width: calc(50% - 10px);
                }
                button.half,
                input[type="submit"].half {
                    width: calc(50% - 6px);
                }
                .register-footer {
                    margin: -5px 0 0 0;
                    color: $text-footer;
                }

                .full-width {
                    width: 100%;
                }
                .left {
                    float: left;
                }
                .right {
                    float: right;
                }
            }
        }

        .register-footer {
            padding: 13px 0 0 0;
            margin: 0px;
            font-size: 13px;
        }

        .submit-button {
            background-color: $primary-color;
            border: none;
            color: $carousel-button;

            .arrow {
                background-image: url("../../assets/images/right-arrow-dark.png");
                width: 15px;
                height: 26px;
                background-size: cover;
            }
        }

        p {
            color: $text-primary;
        }

        .btn-large {
            background-color: #a4a4a4;
            width: 100%;
            height: 100%;
        }

        .dropdowntext {
            text-align: center;
            text-align-last: center;
        }
        .text {
            float: left;
            color: #aeaeae;
        }

        @media (max-width: 767px) {
            .profile-content .form-container.form-body > * .form-col {
                width: 100%;
            }
            .profile-content .form-container.form-body > * .form {
                min-width: 100%;
                flex-wrap: wrap;
            }
        }
        @media (max-width: 500px) {
            .form-container {
                min-width: 100%;
                &.form-body .form {
                    flex-direction: column;
                }
                &.form-body .form-col {
                    width: 100%;
                }
            }
        }
    }
}
</style>

<template>
    <div class="profile-page">
        <div class="profile-content">
            <h1 class="form-container form-header">
                {{ t("title") }}
            </h1>

            <div
                :class="[{ active: 1 || isFormOpen }]"
                class="form-container form-body"
            >
                <form
                    v-if="!showSuccess"
                    action="/user/profile"
                    method="post"
                    autocomplete="off"
                >
                    <div class="form">
                        <div class="form-col">
                            <div class="form-group">
                                <input
                                    id="profile-firstname"
                                    :placeholder="t('firstname')"
                                    disabled
                                    name="name"
                                    type="text"
                                    maxlength="255"
                                    v-model="profileModel.firstname"
                                />
                                <label
                                    for="profile-firstname"
                                    class="text"
                                    v-html="t('firstname')"
                                ></label>
                                <p class="error" v-if="modelErrors.firstname">
                                    {{ modelErrors.firstname[0] }}
                                </p>
                            </div>
                            <div class="form-group">
                                <input
                                    id="profile-name"
                                    :placeholder="t('lastname')"
                                    disabled
                                    name="name"
                                    type="text"
                                    maxlength="255"
                                    v-model="profileModel.lastname"
                                />
                                <label
                                    class="text"
                                    for="profile-name"
                                    v-html="t('lastname')"
                                ></label>
                                <p class="error" v-if="modelErrors.lastname">
                                    {{ modelErrors.lastname[0] }}
                                </p>
                            </div>

                            <div class="form-group">
                                <input
                                    id="profile-password"
                                    :placeholder="t('password')"
                                    name="password"
                                    type="password"
                                    maxlength="255"
                                    v-model="profileModel.password"
                                    autocomplete="new-password"
                                />
                                <label
                                    class="text"
                                    for="profile-password"
                                    v-html="t('password')"
                                ></label>
                                <p class="error" v-if="modelErrors.password">
                                    {{ modelErrors.password[0] }}
                                </p>
                            </div>
                            <div class="form-group">
                                <input
                                    id="profile-password_confirm"
                                    :placeholder="t('password_confirm')"
                                    name="password_confirm"
                                    type="password"
                                    maxlength="255"
                                    v-model="profileModel.password_confirm"
                                    autocomplete="new-password"
                                />
                                <label
                                    class="text"
                                    for="profile-password_confirm"
                                    v-html="t('password_confirm')"
                                ></label>
                                <p
                                    class="error"
                                    v-if="modelErrors.password_confirm"
                                >
                                    {{ modelErrors.password_confirm[0] }}
                                </p>
                            </div>
                        </div>
                        <div class="form-col">
                            <div class="form-group">
                                <input
                                    id="profile-address1"
                                    :placeholder="t('address1')"
                                    name="address1"
                                    type="text"
                                    maxlength="255"
                                    v-model="profileModel.address1"
                                />
                                <label
                                    class="text"
                                    for="profile-address1"
                                    v-html="t('address1')"
                                ></label>
                                <p class="error" v-if="modelErrors.address1">
                                    {{ modelErrors.address1[0] }}
                                </p>
                            </div>
                            <div class="form-group">
                                <input
                                    id="profile-address2"
                                    :placeholder="t('address2')"
                                    name="address2"
                                    type="text"
                                    maxlength="255"
                                    v-model="profileModel.address2"
                                />
                                <label
                                    class="text"
                                    for="profile-address2"
                                    v-html="t('address2')"
                                ></label>
                                <p class="error" v-if="modelErrors.address2">
                                    {{ modelErrors.address2[0] }}
                                </p>
                            </div>
                            <div class="form-group">
                                <input
                                    id="profile-city"
                                    :placeholder="t('city')"
                                    name="city"
                                    type="text"
                                    maxlength="255"
                                    v-model="profileModel.city"
                                />
                                <label
                                    class="text"
                                    for="profile-city"
                                    v-html="t('city')"
                                ></label>
                                <p class="error" v-if="modelErrors.city">
                                    {{ modelErrors.city[0] }}
                                </p>
                            </div>
                            <div class="form-group">
                                <select
                                    id="profile-state"
                                    :placeholder="t('state')"
                                    v-bind:class="{
                                        'placeholder-font': !profileModel.state
                                    }"
                                    name="state"
                                    v-on:focus="profileModel.state || 'AL'"
                                    v-model="profileModel.state"
                                >
                                    <option value="" disabled hidden>{{
                                        t("state")
                                    }}</option>
                                    <option
                                        v-for="(state,
                                        stateIndex) in formData.state"
                                        :value="stateIndex"
                                        :selected="
                                            stateIndex == profileModel.state
                                        "
                                        >{{ state }}</option
                                    >
                                </select>
                                <label
                                    class="text"
                                    for="profile-state"
                                    v-html="t('state')"
                                ></label>
                                <p class="error" v-if="modelErrors.state">
                                    {{ modelErrors.state[0] }}
                                </p>
                            </div>
                            <div class="form-group">
                                <input
                                    id="profile-zip"
                                    :placeholder="t('zip')"
                                    name="zip"
                                    type="text"
                                    maxlength="5"
                                    v-model="profileModel.zip"
                                />
                                <label
                                    class="text"
                                    for="profile-zip"
                                    v-html="t('zip')"
                                ></label>
                                <p class="error" v-if="modelErrors.zip">
                                    {{ modelErrors.zip[0] }}
                                </p>
                            </div>
                            <div class="form-group">
                                <select
                                    name="profile-location"
                                    :placeholder="t('location')"
                                    id="profile-location"
                                    style="height:40px;"
                                    v-model="profileModel.location_designation"
                                >
                                    <option
                                        :value="null"
                                        v-html="
                                            t(
                                                locationOptions.length
                                                    ? 'location'
                                                    : 'loading_text'
                                            )
                                        "
                                        disabled
                                    ></option>
                                    <option
                                        v-for="(cm, idx) in locationOptions"
                                        :key="idx"
                                        :value="cm.id"
                                        v-html="cm.name"
                                    ></option>
                                </select>
                                <label
                                    class="text"
                                    for="profile-location"
                                    v-html="t('location')"
                                ></label>
                                <p class="error" v-if="modelErrors.location">
                                    {{ modelErrors.location[0] }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <button
                        class="profile-footer form-container button"
                        v-on:click="nextButton($event, true)"
                    >
                        Save
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
import PageSection from "../mixins/PageSection";

const defaultTranslations = {
    loading_text: "Loading"
};

export default {
    name: "profile-page",
    computed: {
        pageContent() {
            return this.$store.state.pageContent;
        },
        submitButton() {
            if (this.$store.state.pageContent != null)
                return this.$store.state.pageContent.Profile.content.submit
                    .translation;
            return "";
        },
        content() {
            if (this.$store.state.pageContent != null)
                return this.$store.state.pageContent.Profile.content;
            return "";
        },
        locationOptions() {
            return this.$store.state.user.location_options || [];
        }
    },
    created() {
        this.userData =
            JSON.parse(JSON.stringify(this.$store.state.user)) || {};

        this.$http.get("/api/content/page").then(
            response => {
                if (response.data.success === true) {
                    PageSection.methods.setData(response);
                } else {
                    throw new Error("Could not retrieve content.");
                }
            },
            response => {
                throw new Error("Error retrieving content.");
            }
        );
    },
    data() {
        var userData = JSON.parse(JSON.stringify(this.$store.state.user)) || {};

        return {
            pageName: "Profile",
            isFormOpen: true,
            modelHasErrors: false,
            showSuccess: false,
            modelErrors: {},
            profileModel: {
                ...userData,
                // If the user's current location isn't in the options, set to null to force selection of new value.
                location_designation: userData.location_options.find(
                    location => location.id === userData.location_designation
                )
                    ? userData.location_designation
                    : null
            },
            formData: window.formData || {}
        };
    },
    methods: {
        t(name) {
            if (
                this.content != "" &&
                typeof this.content[name] != "undefined"
            ) {
                return this.content[name].translation;
            }
            return defaultTranslations[name] ?? "";
        },
        nextButton(e, stopPageWhenNotValidated) {
            e.stopPropagation();
            e.preventDefault();
            this.modelHasErrors = false;
            this.modelErrors = {};

            // If password fields are empty, remove from request so that they aren't required by backend.
            if (
                !this.profileModel["password"] &&
                !this.profileModel["password_confirm"]
            ) {
                delete this.profileModel["password"];
                delete this.profileModel["password_confirm"];
            }

            // validate the form in its entirety
            let formData = this.profileModel;

            this.$http.post("/api/user/profile", formData).then(
                response => {
                    if (response.data.success === true) {
                        //check if there are errors
                        this.modelHasErrors = response.data.hasErrors || false;

                        // found errors - must display them
                        if (
                            stopPageWhenNotValidated === true &&
                            this.modelHasErrors === true
                        ) {
                            this.modelErrors = response.data.errors;
                            return;
                        }

                        // After submission, clear password from local data.
                        delete this.profileModel["password"];
                        delete this.profileModel["password_confirm"];

                        this.$emit("saved", this.profileModel);
                    } else {
                        throw new Error("Could not validate content.");
                    }
                },
                error => {
                    //check if there are errors
                    this.modelHasErrors =
                        error.response.data.hasErrors || false;

                    // found errors - must display them
                    if (
                        stopPageWhenNotValidated === true &&
                        this.modelHasErrors === true
                    ) {
                        this.modelErrors = error.response.data.errors;
                        return;
                    }

                    throw new Error("Error validating content.");
                }
            );
        }
    },
    components: {}
};
</script>
